import { useEffect, useState, useRef } from "react";
import {
  Container,
  Link,
  Typography,
  Breadcrumbs,
  Divider,
  Grid,
  Stack,
  SvgIcon,
  Box,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
  Modal,
  Button,
  TextField,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import {
  useLazyRemainingWishCountQuery,
  useRemainingWishCountQuery,
  useSendUrlMutation,
  useUploadImageMutation,
} from "../redux/api/genieApi";

import { ReactComponent as TextIcon } from "../assets/ico_text.svg";
import { ReactComponent as ImageIcon } from "../assets/ico_image.svg";
import { ReactComponent as UrlIcon } from "../assets/ico_url.svg";
import { ReactComponent as WishIcon } from "../assets/ico_wish.svg";
import TotalPanel from "../components/genie/TotalPanel";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useGetProductQuery } from "../redux/api/productApi";
import { useGetPromptNamesQuery, useRunPromptMutation } from "../redux/api/promptApi";
import { setComplexity, setMsg } from "../redux/features/genieSlice";
import { setPromptName } from "../redux/features/genieSlice";
import { useNavigate } from "react-router-dom";
import MagicWand from "../components/MagicWand";
import { setUser } from "../redux/features/userSlice";
import { useCookies } from "react-cookie";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Prompt from "../components/Prompt";
import { useReduceWishCountQuery } from "../redux/api/subscriptionApi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faSpinner as fasSpinner } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';



const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;

const loadTypes = [
  {
    icon: (
      <Box
        sx={{
          bgcolor: "#E06F41",
          padding: "10px",
          borderRadius: "4px",
          fontSize: "0px !important",
        }}
      >
        <SvgIcon>
          <TextIcon />
        </SvgIcon>
      </Box>
    ),
  },
  {
    icon: (
      <Box
        sx={{
          bgcolor: "#7AB65D",
          padding: "10px",
          borderRadius: "4px",
          fontSize: "0px !important",
        }}
      >
        <SvgIcon sx={{ bgcolor: "#7AB65D" }}>
          <ImageIcon />
        </SvgIcon>
      </Box>
    ),
  },
  {
    icon: (
      <Box
        sx={{
          bgcolor: "#0168B5",
          padding: "10px",
          borderRadius: "4px",
          fontSize: "0px !important",
        }}
      >
        <SvgIcon sx={{ bgcolor: "#0168B5" }}>
          <UrlIcon width={24} height={24} />
        </SvgIcon>
      </Box>
    ),
  },
];

const CodeBox = styled(Box)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.mode === "dark" ? "#737373" : "#E3E3E3",
  borderRadius: 4,
  height: "500px",
  overflow: "auto",
  backgroundColor: "#F5F5F5",
  "::-webkit-scrollbar": {
    width: 4,
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#F5F5F5",
    borderRadius: 4,
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.mode === "dark" ? "#2D2D2D" : "#fff",
  },
}));

const GeniePage = () => {
  const navigate = useNavigate();


  // Check if the user is logged in (you need to implement this logic)
  const isAuthenticated = localStorage.getItem("logged_in"); ///* Implement your authentication logic */ true;
  const storageMsg = localStorage.getItem("msg");
  let isPaid = localStorage.getItem("payment");
  const smart_contracte_complexity = localStorage.getItem("complexity");
  const genieSelector = useAppSelector((state) => state.genieState);
  const dispatch = useAppDispatch();
  const [triggerFunction, setTriggerFunction] = useState(false);
  
   // The function to be executed on component mount
   const onLoad = () => {
    //alert('Component has loaded!');
    // Add your code here
  };
  
 //console.log("loggedin:"+localStorage.getItem("logged_in"));
 const promptsInfo = useGetPromptNamesQuery({
  product_name: "CodeGenie",
  product_module: genieSelector.module ? genieSelector.module : "",
});


useEffect(() => {
  // Set triggerFunction to true after some condition or event
  setTriggerFunction(false);
}, []);

useEffect(() => {
  if (promptsInfo.isSuccess) {
    dispatch(setPromptName(promptsInfo.data[0]));
    //alert('test....1');
    //clear_prompts();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [promptsInfo]);



  useEffect(() => {
    // Redirect to the login page if not logged in
    if (isAuthenticated!="true") {
      navigate('/signin');
    }
  }, [isAuthenticated, navigate]);

  const [value, setValue] = useState(0);
  const [code, setCode] = useState(``);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openPaidModal, setOpenPaidModal] = useState(false);
  const wrapperRef = useRef<HTMLInputElement>(null);
  const [url, setURL] = useState("");
  const [wishRemCount, setWishRemCount] = useState(0);

  const user = useAppSelector((state) => state.userState.user);

  const dipatch = useAppDispatch();
  

  const productState = useGetProductQuery({
    _id: "",
    product_name: "CodeGenie",
    product_module: genieSelector.module ? genieSelector.module : "",
  });

  const [runPrompt, runState] = useRunPromptMutation({});
  const [uploadImage, uploadState] = useUploadImageMutation();
  const [sendUrl, urlState] = useSendUrlMutation();
  const remainingWishCountQuery = useRemainingWishCountQuery({"email": user?.email||""});
 
  const [remainingWishCount, remWishState] = useLazyRemainingWishCountQuery();

  const [auditReportData, setAuditReportData] = useState("");
  const [summaryData, setSummaryData] = useState("");
  const [analysisData, setAnalysisData] = useState("");
  const [bugFixesData, setBugFixesData] = useState("");
  const [complexityData, setComplexityData] = useState("");
  const [testCasesData, setTestCasesData] = useState("");
  const [newFeaturesData, setNewFeaturesData] = useState("");
  const [refactorData, setRefactorData] = useState("");
  const [structureData, setStructureData] = useState("");
  const [microservicesData, setMicroservicesData] = useState("");
  const [securityData, setSecurityData] = useState("");
  const [standardData, setStandardData] = useState("");
  const [smileData, setSmileData] = useState("");
  const [finalData, setFinalData] = useState<string | any>("");
  const [loadingIco, setLoadingIco] = useState(false);

  let newData: Record<string, any>  = {"key1":""};
  let tmpComplexityData = "";
  let newDataComplete: Record<string, boolean> = {"key1":true};
  let allCompleted = 0;
  let allSet = true;
  let totalprompts = 0;

  localStorage.setItem("loadingIco","false")


  async function fetchData(prompt: string, code: string, mode: string="") {
      try {
            const params = {
                prompt: prompt,
                code: code,
                product_name: "CodeGenie",
                product_module: genieSelector.module
                  ? genieSelector.module
                  : "",
                email: user?.email||"",
                free_plan: user?.free_plan||0,
                subscription_level: user?.subscription_level||"Free"
            };
            const options = {
                method: 'POST',
                body: JSON.stringify(params)
            };
            const url = baseUrl+'prompts/run_stream_test';
            // const url = 'http://127.0.0.1:8000/completion';
            const response = await fetch(url, options);
            //console.log("response", response)
            if (!response.ok || !response.body) {
                throw response.statusText;
            }
            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            newDataComplete[prompt] = false;
            let newData1 = null;

            while (true) {
                //console.log("inside while......")
                const {value, done} = await reader.read();
                  
               
                if (done) {
                    newDataComplete[prompt] = true;
                    allCompleted++;
                    //console.log("-------------");
                    //console.log(mode);
                    if (prompt == "COMPLEXITY" && newDataComplete[prompt] == true && mode!="AUDIT REPORT"){
                      setComplexity(tmpComplexityData);
                    }

                    

                    console.log(totalprompts+"=="+allCompleted);
                    //if(promptsInfo.data?.length==promptCompleted) {
                    if(totalprompts==allCompleted) {
                      allSet = true;
                      setLoadingIco(false);
                      localStorage.setItem("loadingIco","false");
                      console.log("***********1********");
                      console.log(localStorage.getItem("loadingIco"));
                      localStorage.setItem("allSet",JSON.stringify(allSet));

                      if(complexityData)
                        setComplexity(complexityData);
     
    
                      setOpenModal(true);
                      isPaid = null;
                    }

                    if (wishRemCount>0 && prompt =="SUMMARY" && genieSelector.module?.toLocaleLowerCase() == "any code" && (localStorage.getItem("free_plan") == "1" || localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3"|| localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_4")) {
                      reduceWishCount(user?.email?.toString() || "");
                    }
                    

                    break;
                }
                
                if (prompt === "SUMMARY") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setSummaryData(value => value + decodedChunk)
                    if (newData[prompt]) {
                      newData[prompt] = newData[prompt].toString() +  decodedChunk;
                    } else {
                      newData[prompt] =  decodedChunk;
                    }

                    setFinalData(newData);
                   
                    let { 'key1': deletedProperty, ...newData1 } = newData;
                    newData = newData1;
                    localStorage.setItem("finalData",JSON.stringify(newData));
                }else if (prompt === "FIX BUGS") {
                  const decodedChunk = decoder.decode(value, {stream: true});
                  setBugFixesData(value => value + decodedChunk)
                  if (newData[prompt]) {
                    newData[prompt] = newData[prompt].toString() +  decodedChunk;
                  } else {
                    newData[prompt] =  decodedChunk;
                  }
                  let { 'key1': deletedProperty, ...newData1 } = newData;
                  newData = newData1;
                  localStorage.setItem("finalData",JSON.stringify(newData));
               }
               else if(prompt == "COMPLEXITY" ){
                  const decodedChunk = decoder.decode(value, {stream: true});
                  //alert('setcomplexity');
                  setComplexityData(value => value + decodedChunk)
                  
                  if (tmpComplexityData){
                    tmpComplexityData = tmpComplexityData.toString() +  decodedChunk;
                  }
                  else{
                    tmpComplexityData =   decodedChunk;
                  }
               }
               else if (prompt === "SUMMARY" && isPaid && genieSelector.module?.toLocaleLowerCase() == "smart contracts") {
                      const decodedChunk = decoder.decode(value, {stream: true});

                      if (newData[prompt]) {
                        newData[prompt] = newData[prompt].toString() +  decodedChunk;
                      } else {
                        newData[prompt] =  decodedChunk;
                      }
                      let { 'key1': deletedProperty, ...newData1 } = newData;
                      newData = newData1;
                      localStorage.setItem("finalData",JSON.stringify(newData));

                      let completeData = "";
                      Object.keys(newData).forEach(key => {
                        if(key!=="key1")
                          completeData = completeData + key + ":\n\n" + newData[key] + "\n\n";
                        //console.log(`Key: ${key}, Value: ${newData[key]}`);
                      });

                      //console.log("setAuditReportData ******************")
                    //console.log(auditReportData)
                    
                    setAuditReportData(value => completeData);
               }
               else {
                  
                  //
                  const decodedChunk = decoder.decode(value, {stream: true});
                  //newData[prompt] = newData[prompt] + value + decodedChunk;
                  //console.log(value + decodedChunk)
         
                    if (newData[prompt]) {
                      newData[prompt] = newData[prompt].toString() +  decodedChunk;
                    } else {
                      newData[prompt] =  decodedChunk;
                    }

                    setFinalData(newData);
                    
                    let { 'key1': deletedProperty, ...newData1 } = newData;
                    newData = newData1;
                    localStorage.setItem("finalData",JSON.stringify(newData));
  
                    let completeData = "";
                    Object.keys(newData).forEach(key => {
                      if(key!=="key1")
                        completeData = completeData + key + ":\n\n" + newData[key] + "\n\n";
                      //console.log(`Key: ${key}, Value: ${newData[key]}`);
                    });

                    //console.log("setAuditReportData ******************")
                   //console.log(auditReportData)
                  
                   setAuditReportData(value => completeData);

  
                  
               }
               /*else if (prompt === "ANALYSIS") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setAnalysisData(value => value + decodedChunk)
                }else if (prompt === "TEST CASES") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setTestCasesData(value => value + decodedChunk)
                }else if (prompt === "NEW FEATURES") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setNewFeaturesData(value => value + decodedChunk)
                }else if (prompt === "REFACTOR") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setRefactorData(value => value + decodedChunk)
                }else if (prompt === "STRUCTURE") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setStructureData(value => value + decodedChunk)
                }else if (prompt === "MICROSERVICES") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setMicroservicesData(value => value + decodedChunk)
                }else if (prompt === "SECURITY") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setSecurityData(value => value + decodedChunk)
                }else if (prompt === "STANDARDS") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setStandardData(value => value + decodedChunk)
                }else if (prompt === "SMILE") {
                    const decodedChunk = decoder.decode(value, {stream: true});
                    setSmileData(value => value + decodedChunk)
                }
                */
            }
        } catch (error) {
            console.log(error)
        }
    }
    // const runState = {
    //     isSuccess: true,
    //     isLoading: false,
    //     data: {
    //
    //     }
    // }

    //clear prompts while chaning modules
    function clear_prompts(){

      if(localStorage.getItem("module") != localStorage.getItem("preProduct") || localStorage.getItem("module")=="SMART CONTRACTS")
      {

        localStorage.setItem("preProduct",localStorage!.getItem("module")!);
        setSummaryData("");
        setBugFixesData("");
        setAuditReportData("");
        setComplexityData("");
        localStorage.removeItem("msg");
        localStorage.removeItem("localStorage_msg");
        localStorage.removeItem("finalData");
        localStorage.setItem("allSet","false");
        localStorage.setItem("complexity","");
        isPaid=null;
        
        setTriggerFunction(true);
        //handelChangeRef.current.handleChange(undefined, 0);
      }
    }
    
    

    function handleRun(code: string="", mode: string) {

     

        allCompleted = 0;
        allSet = false;
        localStorage.setItem("allSet",JSON.stringify(allSet));

        
        setSummaryData("")
        setBugFixesData("")
        setAuditReportData("")
        setComplexityData("")
        /*
        setAnalysisData("")
        setTestCasesData("")
        setNewFeaturesData("")
        setRefactorData("")
        setStructureData("")
        setMicroservicesData("")
        setSecurityData("")
        setStandardData("")
        setSmileData("")
        fetchData("SUMMARY", code).then(r => console.log("done SUMMARY"))
        fetchData("ANALYSIS", code).then(r => console.log("done ANALYSIS"))
        fetchData("FIX BUGS", code).then(r => console.log("done FIX BUGS"))
        fetchData("TEST CASES", code).then(r => console.log("done TEST CASES"))
        fetchData("NEW FEATURES", code).then(r => console.log("done NEW FEATURES"))
        fetchData("REFACTOR", code).then(r => console.log("done REFACTOR"))
        fetchData("STRUCTURE", code).then(r => console.log("done STRUCTURE"))
        fetchData("MICROSERVICES", code).then(r => console.log("done MICROSERVICES"))
        fetchData("SECURITY", code).then(r => console.log("done SECURITY"))
        fetchData("STANDARDS", code).then(r => console.log("done STANDARDS"))
        fetchData("SMILE", code).then(r => console.log("done SMILE"))
        */

        let i=0;
        let execute = 0;

        promptsInfo.data?.forEach((item,index) => {
          //console.log(`Item at index ${index}: ${item}`)


          execute = 0;
          if((item == "SUMMARY" || item == "COMPLEXITY") && !isPaid )
          {
            execute = 1;
          }
          else if (wishRemCount>0 && genieSelector.module?.toLocaleLowerCase() == "any code" && (item == "FIX BUGS" || item == "SUMMARY") 
              && ( localStorage!.getItem("free_plan") == "1" || localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")) {
            execute = 1;
          }
          else if (wishRemCount>0 && genieSelector.module?.toLocaleLowerCase() == "any code" && 
          (localStorage!.getItem("free_plan") == "1" || localStorage.getItem("subscription_level") == "level_2" || localStorage.getItem("subscription_level") == "level_4")) {
            execute = 1;
          }
          else if (genieSelector.module?.toLocaleLowerCase() == "smart contracts" && isPaid) {
            execute = 1;
          }

          

          if (execute==1){


            console.log("executed............", item);
            console.log(execute)
            totalprompts++;
            setLoadingIco(true)
            localStorage.setItem("loadingIco","true")
            console.log("***********2********");
                      console.log(localStorage.getItem("loadingIco"));
            fetchData(item, code, mode).then(r => console.log(`done  ${item}`))

                  //CALL reduce wish count
            // if(i==0 && genieSelector.module?.toLocaleLowerCase() == "any code"){
            //   reduceWishCount(user?.email?.toString() || "");
            // }

           

          }
                  
          i++;
        })

        

       


    }
  // console.log("runState", runState)

  async function reduceWishCount(userId: String){
    const params = {
      userid: userId
    };
    const options = {
        method: 'GET',
    };
    //reduce wish count
    const url = baseUrl+'prompts/reduceWishCount?userid=' + userId.toString();
    // const url = 'http://127.0.0.1:8000/completion';
    const response = await fetch(url, options); 
    console.log("response********************", response);

    if (response.ok || response.body) {
        let wscount = wishRemCount - 1;
        if(wscount<0) wscount = 0;
        setWishRemCount(wscount);
        console.log("wishRemCount--------");
        console.log(wscount)
        //throw response.statusText;
    }
  }

  function setComplexity(data: String)
  {
      //toast.success("Executed successfully");
      //console.log(runState.data);
      //dipatch(setMsg(data));
      localStorage.setItem("msg", data.toString());
      localStorage.removeItem("payment");
      //console.log(genieSelector.module);
      if(genieSelector.module == "SMART CONTRACTS"){
      const content = data;
      let complexity = "";

      
      // Create a regular expression pattern to match lines containing both "contract" and "complexity"
      const pattern = /complexity.*?(Low|High|Medium)/gs;

     
       //replace particular line to fix the issue in finding complexity
       const content1 = content.replace("Complexity can be Low, Medium, High, Very High. Pricing for smart contract auditing is based on the complexity of the contract","");

      // Use the regular expression to find and extract the desired portion
      const matches = content1.match(pattern);

      let foundstring = "Error";

      if (matches) {
        //console.log("Extracted portion:");
        //console.log("count:::"+matches.length);
        //console.log(matches[0]);
        foundstring = matches[0];
      } else {
        foundstring = "Error";
        toast.error("No complexity found");
        //console.log("No matching portion found.");
      }

      /*
      const indexOfLow = content.includes('The complexity of the smart contract is Low.')
      const indexOfMedium = content.includes('The complexity of the smart contract is Medium.')
      const indexOfHigh = content.includes('The complexity of the smart contract is High.')
      const indexOfVeryHigh = content.includes('The complexity of the smart contract is Very High.')

      const contract_indexOfLow = content.includes('The complexity of the smart contract is Low.')
      const contract_indexOfMedium = content.includes('The complexity of the smart contract is Medium.')
      const contract_indexOfHigh = content.includes('The complexity of the smart contract is High.')
      const contract_indexOfVeryHigh = content.includes('The complexity of the smart contract is Very High.')
    
      
      if(indexOfLow == true || contract_indexOfLow == true) complexity = "Low";
      else if(indexOfMedium == true || contract_indexOfMedium == true) complexity = "Medium";
      else if(indexOfHigh == true || contract_indexOfHigh == true) complexity = "High";
      else if(indexOfVeryHigh == true || contract_indexOfVeryHigh == true) complexity = "Very High";
      else complexity = "Low";

      */

      if (foundstring.includes("Very High"))
        complexity = "Very High";
      else if (foundstring.includes("High"))
        complexity = "High";
      else if (foundstring.includes("Medium"))
        complexity = "Medium";
      else if (foundstring.includes("Low"))
        complexity = "Low";
      else if (foundstring.includes("Error"))
        complexity = "Error";
         
      //console.log(complexity);
      //dipatch(setComplexity(complexity));

      localStorage.setItem("complexity", complexity);
    }

      remainingWishCount({"email":user?.email||""});
      const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
      
  }


  // useEffect with an empty dependency array runs once when the component mounts
  useEffect(() => {
    onLoad();
    //alert('test....2');
    //clear_prompts();
  }, []); // The empty dependency array ensures that the effect runs only once
  

  useEffect(() => {
    if (uploadState.isSuccess) {
      setCode(uploadState.data.content);
    }
  }, [uploadState]);

  useEffect(() => {
    if(remainingWishCountQuery.isSuccess)
    setWishRemCount(remainingWishCountQuery.data?.remaining_wish_count);
  }, [remainingWishCountQuery]);

  useEffect(() => {
    if (runState.isSuccess) {
      toast.success("Executed successfully");
      //console.log(runState.data);
      dipatch(setMsg(runState.data));
      localStorage.setItem("msg", JSON.stringify(runState.data));
      localStorage.removeItem("payment");
      //console.log(genieSelector.module);
      if(genieSelector.module == "SMART CONTRACTS"){
      const content = JSON.parse(String(localStorage.getItem("msg")))["COMPLEXITY"];
      let complexity = "";


      // Create a regular expression pattern to match lines containing both "contract" and "complexity"
      const pattern = /complexity.*?(Low|High|Medium)/gs;

       //replace particular line to fix the issue in finding complexity
       const content1 = content.replace("Complexity can be Low, Medium, High, Very High. Pricing for smart contract auditing is based on the complexity of the contract","");

      // Use the regular expression to find and extract the desired portion
      const matches = content1.match(pattern);

      let foundstring = "Error";

      if (matches) {
        //console.log("Extracted portion:");
        //console.log("count:::"+matches.length);
        //console.log(matches[0]);
        foundstring = matches[0];
      } else {
        foundstring = "Error";
        toast.error("No complexity found");
        //console.log("No matching portion found.");
      }

      /*
      const indexOfLow = content.includes('The complexity of the smart contract is Low.')
      const indexOfMedium = content.includes('The complexity of the smart contract is Medium.')
      const indexOfHigh = content.includes('The complexity of the smart contract is High.')
      const indexOfVeryHigh = content.includes('The complexity of the smart contract is Very High.')

      const contract_indexOfLow = content.includes('The complexity of the smart contract is Low.')
      const contract_indexOfMedium = content.includes('The complexity of the smart contract is Medium.')
      const contract_indexOfHigh = content.includes('The complexity of the smart contract is High.')
      const contract_indexOfVeryHigh = content.includes('The complexity of the smart contract is Very High.')
    
      
      if(indexOfLow == true || contract_indexOfLow == true) complexity = "Low";
      else if(indexOfMedium == true || contract_indexOfMedium == true) complexity = "Medium";
      else if(indexOfHigh == true || contract_indexOfHigh == true) complexity = "High";
      else if(indexOfVeryHigh == true || contract_indexOfVeryHigh == true) complexity = "Very High";
      else complexity = "Low";

      */

      if (foundstring.includes("Very High"))
        complexity = "Very High";
      else if (foundstring.includes("High"))
        complexity = "High";
      else if (foundstring.includes("Medium"))
        complexity = "Medium";
      else if (foundstring.includes("Low"))
        complexity = "Low";
      else if (foundstring.includes("Error"))
        complexity = "Error";
         
      //console.log(complexity);
      //dipatch(setComplexity(complexity));
      localStorage.setItem("complexity", complexity);
    }

      remainingWishCount({"email":user?.email||""});
      setOpenModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runState]);
  
  useEffect(() => {
    
    //if (isPaid == null || smart_contracte_complexity == null) {
      if (isPaid == null) {
      
      localStorage.removeItem("msg");
      localStorage.removeItem("complexity");
      localStorage.removeItem("payment");
    }

    if(isPaid == "Success"){
      localStorage.removeItem("complexity");
      let storedcode = localStorage.getItem("code");
      console.log("code..........after....payment");
      console.log(storedcode);
      if (storedcode !== null) {
        console.log("Calling handleRun.");
        setAuditReportData("")
        handleRun(storedcode, "AUDIT REPORT");
      }
      
        
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaid, promptsInfo, setAuditReportData]);

  useEffect(() => {
    if (remWishState.isSuccess) {
      setWishRemCount(remWishState.data?.remaining_wish_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remWishState]);

  useEffect(() => {
    if (urlState.isSuccess) setCode(urlState.data.content);
  }, [urlState]);

  if (productState.isLoading || productState.isFetching || !productState.data)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      onClick={() => {
        navigate("/");
      }}
    >
      Home
    </Link>,
    <Typography key="3" color="text.primary">
      {genieSelector.module}
    </Typography>,
  ];

  const {
    module_description,
    source_check,
    source_text,
    source_image,
    source_url,
    input_box_title,
    input_box_description,
    export_check,
    export_text,
    export_pdf,
    export_word,
  } = productState.data;

  return (
    <>
      {/* {loadingIco && (
        <Box position="absolute">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <Box>
              <MagicWand />

              <Typography textAlign="center">{(genieSelector.module != "SMART CONTRACTS")? "Auditing Code":"Auditing Contract"}</Typography>

            </Box>
          </Backdrop>
        </Box>
      )} */}

   
  

      <Container maxWidth="xl" sx={{ height: "calc(100vh - 100px)" }}>
      <div className="row">
        <div className="col-md-12 col-sm-12">
              <Stack direction="row" spacing={2} justifyContent="space-between" sx={{paddingTop:2}}>
                <Breadcrumbs
                  separator={<NavigateNext fontSize="small" />} 
                  sx={{ marginY: 3 }}
                >
                  {breadcrumbs}
                </Breadcrumbs>
                
                {
                  genieSelector.module != "SMART CONTRACTS" && <Button
                  sx={{
                    border: "1px solid #4BA5EB",
                    padding: "1px 16px",
                    height: "44px",
                  }}
                >
                  
                  {wishRemCount?wishRemCount:0} {(user?.subscription_level=="level_1" || user?.subscription_level=="level_3")?"Bug Fixes ":"Audits "} left
                </Button>}
              </Stack>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 col-sm-12">
            <Stack height="100%">
              
              
              <Divider />
              {/*<Grid container flexGrow={1}>*/}
                <Grid item xs={5} paddingTop={2} >
                  <Typography mb={3}>{module_description}</Typography>
                  
                  <Stack spacing={3} height="100%">
                    <input
                      type="file"
                      hidden
                      ref={wrapperRef}
                      onChange={(event) => {
                        let formData = new FormData();
                        if (event.target.files) {
                          formData.append("image", event.target.files[0]);
                          uploadImage(formData);
                        }
                      }}
                    />
                    <Tabs
                      orientation={isMobile ? 'vertical' : 'horizontal'}
                      onChange={(e: React.SyntheticEvent, newValue: number) =>
                        setValue(newValue)
                      }
                      value={value}
                      sx={{
                        "& .MuiTabs-scroller .MuiTabs-flexContainer": { gap: 2 },
                      }}
                    >
                      {source_check.includes("source_text") && (
                        <Tab
                          sx={{
                            width: isMobile? "100%" : "30%",
                            justifyContent: "left",
                            padding: "8px 16px",
                            border: "1px solid",
                            borderColor: "inherit",
                            borderRadius: 1,
                          }}
                          iconPosition="start"
                          icon={loadTypes[0].icon}
                          label={source_text}
                        />
                      )}
                      {source_check.includes("source_image") && (
                        <Tab
                          sx={{
                            width: isMobile? "100%" : "30%" ,
                            justifyContent: "left",
                            padding: "8px 16px",
                            border: "1px solid",
                            borderColor: "inherit",
                            borderRadius: 1,
                          }}
                          iconPosition="start"
                          icon={loadTypes[1].icon}
                          label={source_image}
                          onClick={() => wrapperRef.current?.click()}
                        />
                      )}
                      {source_check.includes("source_url") && (
                        <Tab
                          sx={{
                            width: isMobile? "100%" : "30%",
                            justifyContent: "left",
                            padding: "8px 16px",
                            border: "1px solid",
                            borderColor: "inherit",
                            borderRadius: 1,
                          }}
                          iconPosition="start"
                          icon={loadTypes[2].icon}
                          label={source_url}
                          onClick={() => setOpen(true)}
                        />
                      )}
                    </Tabs>
                    <Typography variant="h4" color="text.secondary">
                      {input_box_title}
                    </Typography>

                    <CodeBox>
                      <CodeEditor
                        value={code}
                        language="js"
                        placeholder={input_box_description}
                        onChange={(evn) => setCode(evn.target.value)}
                        padding={15}
                        style={{
                          fontSize: 16,
                          backgroundColor: "#F5F5F5",
                          fontFamily:
                            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                        }}
                      />
                    </CodeBox>
                    <Stack alignItems="end">
                      <LoadingButton
                        startIcon={
                          <SvgIcon>
                            <WishIcon />
                          </SvgIcon>
                        }
                        loading={runState.isLoading}
                        sx={{
                          background:
                            "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
                          marginLeft: 5,
                          color: "white",
                          paddingX: "20px",
                          paddingY: "12px",
                          borderRadius: "4px",
                          width: "fit-content",
                        }}
                        onClick={() => {

                         
                          let exvalid = true; 
                          
                          if (code === "") {
                            toast.error("No empty code!");
                            return;
                          }

                          if (wishRemCount <= 0){
                            localStorage.setItem("free_plan","0")

                          }
                          
                          /*
                          if(wishRemCount <= 0 && genieSelector.module != "SMART CONTRACTS"){
                            toast.error("Please upgrade your plan");
                            return;
                          }*/

                          if(code.length > 15000){
                            toast.error("Sorry, we currently do not support large code files.  Please include only the code section that includes the bug or feature that you want to analyze or fix.");
                            return;
                          }
                          
                          if(genieSelector.module?.toLocaleLowerCase() == "smart contracts"){
                            
                            clear_prompts();

                           
                          }
                          
                          // runPrompt({
                          //   product_name: "CodeGenie",
                          //   product_module: genieSelector.module
                          //     ? genieSelector.module
                          //     : "",
                          //   code: code,
                          //
                          //   email: user?.email||"",
                          //   free_plan: user?.free_plan||0,
                          //   subscription_level: user?.subscription_level||"Free"
                          //
                          // });
                          if(isPaid!='Success')
                            localStorage.setItem("code",code);

                          setLoadingIco(true);
                          localStorage.setItem("loadingIco","true")
                          console.log("***********3********");
                          console.log(localStorage.getItem("loadingIco"));
                          

                          handleRun(code,"");
                          
                        }}
                      >
                      
                      {(genieSelector.module != "SMART CONTRACTS")? "Audit Code":"Audit Contract"}

                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Grid>
                
             
              {/*</Grid>*/}
            </Stack>
          </div>
          
          <div className="col-md-7 col-sm-12">
          {loadingIco && (<div>
            <FontAwesomeIcon  icon={faSpinner as IconDefinition} spin  style={{ fontSize: '24px', marginRight: '8px' }} />
            <span>Processing...</span>
          </div>
          )}
            <Grid item xs={6.9} pl="24px" pt="40px">
              <TotalPanel
                export_check={export_check}
                export_pdf={export_pdf}
                export_word={export_word}
                export_text={export_text}
                summaryData={summaryData}
                bugFixesData={bugFixesData}
                complexityData={complexityData}
                auditReportData={auditReportData}
                triggerFunction={triggerFunction}
              />
            </Grid>

          </div>
        </div>
      </Container>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile? "80%" :400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <h2 id="child-modal-title">URL</h2>
          <p id="child-modal-description">Input code location URL</p>
          <TextField
            value={url}
            onChange={(e) => setURL(e.target.value)}
            error={url === ""}
            helperText={url === "" ? "URL field is required." : ""}
            size="small"
            fullWidth
            placeholder="URL"
            sx={{ mb: 2 }}
          />
          <Stack flexDirection="row">
            <Button
              onClick={() => {
                sendUrl({ url });
                setOpen(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              OK
            </Button>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="contained"
            >
              Close Child Modal
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile? "80%" :600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Click on the tabs to view the results and select "Export" to download the results.</p>
          <Stack flexDirection="row">
            <Button
              onClick={() => {

                setOpenModal(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              OK
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openPaidModal}
        onClose={() => setOpenPaidModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile? "95%" :600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Sorry! you can do this only from a paid plan.</p>
          <Stack flexDirection="row">
            <Button
              onClick={() => {
                setOpenPaidModal(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              OK
            </Button>
          </Stack>
        </Box>
      </Modal>

    </>
  );
};

export default GeniePage;
