import * as React from 'react';
import { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  SvgIcon,
  IconButton,
  Button,
  Divider,
  Tabs,
  Tab,
  Menu,
  Modal,
  MenuItem,
  TextareaAutosize,
  Popper
} from "@mui/material";

import { ReactComponent as CopyIcon } from "../../assets/ico_copy.svg";
import { ReactComponent as ExportIcon } from "../../assets/ico_export.svg";

import { useGetPromptNamesQuery } from "../../redux/api/promptApi";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setPromptName } from "../../redux/features/genieSlice";
import { useExportFileMutation } from "../../redux/api/genieApi";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import PayNowButton from "../../pages/payment/payNowButton";
import { object, string, TypeOf, number } from "zod";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import {
  useLazyRemainingWishCountQuery,
  useRemainingWishCountQuery,
  useSendUrlMutation,
  useUploadImageMutation,
} from "../../redux/api/genieApi";
import { GifBoxRounded, Padding } from "@mui/icons-material";
import { useFeedBackMutation } from '../../redux/api/authApi';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as FeedBackIcon } from '../../assets/ico_feedback.svg'




//from "../r ../redux/api/genieApi";
interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, ...other } = props;

  return (
    <div role="tabpanel" style={{ flex: 1 }} {...other}>
      <Box
        sx={(theme) => ({
          p: 3,
          backgroundColor: theme.palette.background.paper,
          height: "100%",
        })}
      >
        {children}
      </Box>
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const feedbackform = object({
  email: string(),
  rating: number(),
  feedback: string(),
  productname: string(),
  date: string()
});



export type FeedBackForm = TypeOf<typeof feedbackform>

export default function TotalPanel({
  export_check,
  export_text,
  export_pdf,
  export_word,
  summaryData,
  bugFixesData,
  complexityData,
  auditReportData,
  triggerFunction
}: {
  export_check: string[];
  export_text: string;
  export_pdf: string;
  export_word: string;
  summaryData: string;
  bugFixesData: string;
  complexityData: string;
  auditReportData: string;
  triggerFunction: boolean;
}) {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const initialState = {
    copied: false
  }
  const [clipboard, clipboardState] = useState(initialState);

  const genieSelector = useAppSelector((state) => state.genieState);
  //console.log(genieSelector);
  const userSelector = useAppSelector((state) => state.userState);
  const dispatch = useAppDispatch();

  const [exportFile, exportState] = useExportFileMutation();


  const [openPaidModal, setOpenPaidModal] = useState(false);
  const [openPaidModal1, setOpenPaidModal1] = useState(false);
  const [openPaidModalAnyCode, setOpenPaidModalAnyCode] = useState(false);

  const navigate = useNavigate();

  const isPaid = localStorage.getItem("payment");

  //console.log("msg------------");
  //console.log(localStorage.getItem("msg"));
  //let localStorage_msg;
  //let localStorage_msg = JSON.parse(String(localStorage.getItem("msg")));
  //console.log("-----------");
  //console.log(localStorage_msg);
  let localStorage_msg = {
    'SUMMARY': summaryData,
    'FIX BUGS': bugFixesData,
    'COMPLEXITY': complexityData,
    'AUDIT REPORT': auditReportData
  }

  


  if(isPaid){
    //console.log("inside isPaid.....................")
    //console.log(localStorage.getItem("localStorage_msg"))
    localStorage_msg = JSON.parse(String(localStorage.getItem("localStorage_msg")));
    //console.log("localStorage_msg----------------")
    //console.log(localStorage_msg)
  }

  

  //feedback popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const ratings = [1, 2, 3, 4, 5];
  const user = useAppSelector((state) => state.userState.user);
  const [productName, setProductName] = useState<string | null>(localStorage.getItem('module'));
  const currentDate = new Date().toISOString();
  
  const [feedbackForm, setFeedbackForm] = useState<FeedBackForm>({ email: user?.email || "", rating: 0, feedback: '', productname: productName || '', date: currentDate })
  const [feedbackMutation] = useFeedBackMutation();
  const remainingWishCountQuery = useRemainingWishCountQuery({ "email": user?.email || "" })
  const [remainingWishCount, remWishState] = useLazyRemainingWishCountQuery();
  const [wishRemCount, setWishRemCount] = useState(0);
  

  useEffect(() => {
    // Check if triggerFunction is true and perform the desired action
    if (triggerFunction) {
      handleChange(undefined,0);
    }
  }, []);

  useEffect(() => {
    if (remainingWishCountQuery.isSuccess)
      setWishRemCount(remainingWishCountQuery.data?.remaining_wish_count);
  }, [remainingWishCountQuery]);

  useEffect(() => {
    if (remWishState.isSuccess) {
      //console.log(remWishState.data?.remaining_wish_count);
      setWishRemCount(remWishState.data?.remaining_wish_count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remWishState]);

  const handleFormSubmit = () => {
    // Call the feedbackMutation function with the form data as payload
    feedbackMutation(feedbackForm)
      .then((response) => {
        // console.log('Feedback sent successfully:', response);
        toast.success('FeedBack Sent Successfully!!');
        setIsPopupOpen(false);
        setFeedbackForm({
          email: user?.email || "",
          rating: 0,
          feedback: '',
          productname: productName || '',
          date: currentDate
        });

      })
      .catch((error) => {
        console.error('Error sending feedback:', error);

      });
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    // Update feedbackForm with the textarea value
    setFeedbackForm((prevForm) => ({
      ...prevForm,
      feedback: event.target.value,
    }));

  };

  const handleRating = (event: number) => {
    // Update feedbackForm with the textarea value
    setFeedbackForm((prevForm) => ({
      ...prevForm,
      rating: event
    }));

  };

  useEffect(() => {
    
    //console.log(localStorage.getItem("allSet")+"---------");
    if(localStorage.getItem("allSet")=="true")
    {
      localStorage.setItem("localStorage_msg",JSON.stringify(localStorage_msg));
      //console.log(JSON.parse(String(localStorage.getItem("localStorage_msg"))));
    }

  }, [localStorage.getItem("allSet")]);

  useEffect(() => {

    //console.log(userSelector.user?.subscription_level);

    var plan = -1;
    plan = Number(userSelector.user?.subscription_level?.indexOf("Start"));
    
  
      

  }, []);

  const promptsInfo = useGetPromptNamesQuery({
    product_name: "CodeGenie",
    product_module: genieSelector.module ? genieSelector.module : "",
  });

  useEffect(() => {
    if (clipboard.copied) {
      toast.success("Copied to clipboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clipboard]);

  useEffect(() => {
    if (promptsInfo.isSuccess) {
      dispatch(setPromptName(promptsInfo.data[0]));
    }
    if(isPaid && genieSelector.module == "SMART CONTRACTS") {
      handleChange(undefined, 2);
    }
    if((localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3") 
    && genieSelector.module == "Any Code") {
      handleChange(undefined, 2);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promptsInfo]);

  useEffect(() => {
    if (exportState.isSuccess) {
      fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/static/${exportState.data.path}`
      )
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${exportState.data.path}`);
          document.body.appendChild(link);
          link.click();
        });
    }
  }, [exportState]);



  /* const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    debugger
    //console.log("============");
    //console.log(wishRemCount);
    var valid = true;
    //if(isPaid == "Success" || genieSelector.module != "SMART CONTRACTS" ){
    if((isPaid == "Success" && genieSelector.module == "SMART CONTRACTS") || (wishRemCount>0 && genieSelector.module == "Any Code") ){
      valid = true;
      console.log("module"); 
      console.log(genieSelector.module); 
      if(genieSelector.module == "Any Code"){
        console.log("Free plan"); 
        console.log(localStorage.getItem("free_plan"));
        console.log("subscription_level--="+localStorage.getItem("subscription_level"))
        const newvalue = promptsInfo.data ? promptsInfo.data[newValue] : "";
        if(localStorage.getItem("free_plan")!="1")
        {
          if((newvalue !="SUMMARY" && newvalue != "FIX BUGS") && (localStorage.getItem("subscription_level")=="level_1" || localStorage.getItem("subscription_level")=="level_3" ))
          {
            setOpenPaidModalAnyCode(true);
            valid = false;
          }
        }
          
      }
      
    }else{
      //if (userSelector.user?.free_plan ==1){
        //if(newValue!=0 && newValue!=13)
   
        if (promptsInfo.data) {
          if(genieSelector.module == "SMART CONTRACTS"){
            if(promptsInfo.data[newValue]!="FUNCTIONALITY" && promptsInfo.data[newValue]!="COMPLEXITY")
            {
              setOpenPaidModal(true);
              valid = false;
            }
          }
          else if(genieSelector.module == "Any Code"){
            console.log("Free plan"); 
            console.log(localStorage.getItem("free_plan"));
            console.log("subscription_level="+localStorage.getItem("subscription_level"))
            if(promptsInfo.data[newValue]!="SUMMARY" && 
            (localStorage.getItem("free_plan")!="1" || ( (localStorage.getItem("subscription_level")!="level_1" 
            || localStorage.getItem("subscription_level")!="level_3") && localStorage.getItem("subscription_level")!="" ) ) )
            {
              setOpenPaidModalAnyCode(true);
              valid = false;
            }
          }
            
        }
      //}

      //if (userSelector.user?.subscription_level == "Startups"){
      //  if(newValue==12)
      //  {
      //    setOpenPaidModal(true);
      //    valid = false;
      //  }
      //}
    }
    
    if (valid)
    {
      setValue(newValue);
      if (promptsInfo.data) dispatch(setPromptName(promptsInfo.data[newValue]));
    }
      
  }; */

  

  let combinedAuditShown = false;
  const updatedPromptsInfoData = promptsInfo.data?.map((panel, index) => {
    //console.log(panel+"-----****")
    if (genieSelector.module === 'SMART CONTRACTS') {
      if (index === 2) {
       
        return "AUDIT REPORT";
      } else if ( (index >= 3 && index <= 14) || (isPaid && (index <= 1 || (index >= 3 && index <= 14 ) ))){
        
        return "";
      } else {
          return panel; 
      }
    }
    else if (genieSelector.module === 'Any Code') {
      if (index === 3) {
        return "AUDIT REPORT";
      } 
      else if (index > 0 && index < 2) {
        return "";
      }
      else if (index >= 4 && index <= 14) {
        return "";
      } else {
        return panel;
      }
    }

    if (index === 0) {
      return panel;
    } else if (!combinedAuditShown) {
      combinedAuditShown = true;
      return "AUDIT REPORT";
    } else {
      return "";
    }
  });
  //const [completeAudit, setCompleteAudit] = useState('');
  const handleChange = (event: React.SyntheticEvent | undefined, newValue: number) => {
    //alert(newValue);
    //console.log("============");
    //console.log(wishRemCount);
   //console.log(localStorage_msg);
    
    var valid = true;

    //if(isPaid == "Success" || genieSelector.module != "SMART CONTRACTS" ){
    if ((isPaid == "Success" && genieSelector.module == "SMART CONTRACTS") 
        || (wishRemCount > 0 && genieSelector.module == "Any Code")) {
      valid = true;
      //console.log("module....................................");
      //console.log(genieSelector.module);
      if (genieSelector.module == "Any Code") {
        //debugger
        //console.log("Free plan");
        //console.log(localStorage.getItem("free_plan"));
        //console.log("subscription_level--=" + localStorage.getItem("subscription_level"));
        //console.log("promptsInfo-------------------------------");
        //console.log(promptsInfo);
        //console.log("updatedPromptsInfoData-------------------------------");
        //console.log(updatedPromptsInfoData);
        //const newvalue = updatedPromptsInfoData ? updatedPromptsInfoData[newValue] : "";
        const newvalue = promptsInfo.data ? promptsInfo.data[newValue] : "";
        if (newvalue === "AUDIT REPORT") {
          //debugger
          //console.log(genieSelector.msg);
          const messages = Object.keys(localStorage_msg)
            .slice(1, 2).map(key => `\n${key} : \n${(localStorage_msg as any)[key]}`)
            .concat(
              Object.keys(localStorage_msg)
                .slice(3, 15)  
                .map(key => `\n${key} : \n${(localStorage_msg as any)[key]}`)
            )
            .join('\n');
          
          //console.log(messages);
          //setCompleteAudit(messages); //need to review
          //localStorage.setItem('Messages', messages); //need to review

        } 
        //console.log(newvalue);
        if (localStorage.getItem("free_plan") != "1") {
          if ((newvalue != "SUMMARY" && newvalue != "FIX BUGS") && (localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")) {
            const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
   
            localStorage.setItem("localStorage_msg",JSON.stringify(localStorage_msg))
            //if(storedAllSet) 
              setOpenPaidModalAnyCode(true);
            //else
            //  toast.success('Pls. wait, we are working in background.');
            valid = false;
          }
        }

      }
      else if (genieSelector.module == "SMART CONTRACTS"){
         // Get all keys from localStorage
          const keys = Object.keys(localStorage);

          // Iterate over the keys and display the values
          keys.forEach((key) => {
            const value = localStorage.getItem(key);
          });
      }

    } else {
      if (updatedPromptsInfoData) {
       
        if (genieSelector.module == "SMART CONTRACTS") {
          const newvalue = updatedPromptsInfoData ? updatedPromptsInfoData[newValue] : "";
          if (newvalue === "AUDIT REPORT") {
            //debugger
            const messages = Object.keys(genieSelector.msg)
              .slice(2, 15)  
              .map(key => `\n${key} : \n${genieSelector.msg[key]}`)
              .join('\n');

            //console.log(messages);
            //setCompleteAudit(messages); //need to review
            localStorage.setItem('Messages',messages); //need to review
          } 
          if (newvalue != "SUMMARY" && newvalue != "COMPLEXITY") {
        
            const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");

            localStorage.setItem("localStorage_msg",JSON.stringify(localStorage_msg))
            //console.log("storedAllSet -- 2",storedAllSet);
            //if(storedAllSet) //need to review
              setOpenPaidModal(true);
            //else
            //  toast.success('Pls. wait, we are working in background.');
            valid = true;
          }
        }
        else if (genieSelector.module == "Any Code") {
          //console.log("Free plan");
          //console.log(localStorage.getItem("free_plan"));
          //console.log("subscription_level=" + localStorage.getItem("subscription_level"))
          const newvalue = updatedPromptsInfoData ? updatedPromptsInfoData[newValue] : "";

          
          if (newvalue != "SUMMARY" &&
            (localStorage.getItem("free_plan") != "1" || ((localStorage.getItem("subscription_level") != "level_1"
              || localStorage.getItem("subscription_level") != "level_3") && localStorage.getItem("subscription_level") != ""))) {
                const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
           
                localStorage.setItem("localStorage_msg",JSON.stringify(localStorage_msg))
                //console.log("storedAllSet -- 3",storedAllSet);
                // if(storedAllSet)
                setOpenPaidModalAnyCode(true);
                // else
                //   toast.success('Pls. wait, we are working in background.');
            valid = false;
          }
        }

        else{
          const newvalue = updatedPromptsInfoData ? updatedPromptsInfoData[newValue] : "";
          if (newvalue === "AUDIT REPORT") {
            //debugger
            
            const messages = Object.keys(genieSelector.msg)
              .slice(1, 10)  
              .map(key => `\n${key} : \n${genieSelector.msg[key]}`)
              .join('\n');

            //console.log(messages); 
            //setCompleteAudit(messages); //need to review
          //localStorage.setItem('Messages',messages); //need to review
          } 
        }

      }
      //}

      //if (userSelector.user?.subscription_level == "Startups"){
      //  if(newValue==12)
      //  {
      //    setOpenPaidModal(true);
      //    valid = false;
      //  }
      //}
    }

    if (valid) {
      setValue(newValue);
      if (updatedPromptsInfoData) {
        const newVal = updatedPromptsInfoData[newValue];
        if (newVal) {
          dispatch(setPromptName(newVal));
          // setCompleteAudit(genieSelector.msg);
        }
      }
    }

  };

  if (!promptsInfo.data || promptsInfo.isLoading || promptsInfo.isFetching)
    return (
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          display: "flex",
          height: "100%",

          border: "1px solid",
          borderColor: theme.palette.divider,
          borderRadius: 1,
        })}
      ></Box>
    );





  return (
    <>
      <Box
        sx={(theme) => ({
          flexGrow: 1,
          display: "flex",
          height: "1200",
          "& .MuiBox-root": {
            padding: 0,  // Remove padding
          },
          border: "1px solid",
          borderColor: theme.palette.divider,
          borderRadius: 1,
        })}
      >
        <Tabs
          orientation={!isMobile ? 'vertical' : 'horizontal'}
          value={value}
          onChange={handleChange}
          sx={(theme) => ({
            borderRight: 1,
            borderColor: "divider",
            overflow: "scroll",
            "& .MuiBox-root": {
              padding: 0,  // Remove padding
            },
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.background.default
                : "white",
            ".MuiTabs-indicator": {
              visibility: "visible",
            },
            ".MuiTabs-scroller": {
              overflow: "visible !important",
            },
          })}
        >
          {updatedPromptsInfoData?.map((panel, index) => (
            <Tab
              key={`result_panel_${index}`}
              sx={(theme) => ({
                textTransform: "none",
                alignItems: "flex-start",
                color: theme.palette.mode === "dark" ? "#F8FAFC" : "#475569",
                overflow: "visible",
                padding: "24px 14px",
                borderBottom: "1px solid",
                borderColor: "divider",
                "&.Mui-selected": {
                  borderLeft: "3px solid",
                  backgroundColor: theme.palette.background.paper,
                },
                "&.Mui-selected::after": {
                  overflow: "visible",
                  content: '" "',
                  left: "98%",
                  position: "absolute",
                  width: 10,
                  height: "100%",
                  zIndex: 101,
                  backgroundColor: theme.palette.background.paper,
                },
                display: panel ? 'block' : 'none',
              })}
              label={panel}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        {!isMobile ? (
          <TabPanel value={value}>
            <Stack gap="16px" height="100%">
              <Stack flexDirection="row">
                <Typography
                  variant="h5"
                  color="text.secondary"
                  fontWeight="500"
                  flexGrow={1}
                >
                  Results
                </Typography>
                <Stack gap="16px" flexDirection="row">
                  <CopyToClipboard
                    text={  localStorage.getItem("finalData")?JSON.stringify( JSON.parse(String(localStorage.getItem("finalData")))  ):""

                    }
                    onCopy={() => clipboardState({ copied: true })}>
                    <IconButton>
                      <SvgIcon>
                        <CopyIcon />
                      </SvgIcon>
                    </IconButton>
                  </CopyToClipboard>

                  <Button
                    startIcon={
                      <SvgIcon>
                        <ExportIcon />
                      </SvgIcon>
                    }
                    variant="contained"
                    color="inherit"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      //wait until process complete
                      if(localStorage.getItem("loadingIco")=="false"){
                        //console.log(genieSelector.module + "--" + isPaid)
                        if (isPaid == null && genieSelector.module == "SMART CONTRACTS") {
                          setOpenPaidModal1(true);
                        }
                        else  
                          setAnchorEl(anchorEl ? null : event.currentTarget);
                      }
                    }}
                    sx={(theme) => ({
                      borderColor: theme.palette.divider,
                    })}
                  >
                    Export
                  </Button>
                  <Popper
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    style={{
                      backgroundColor: '#373C47', borderRadius: '4px',
                      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
                      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
                    }}
                  // onClose={() => setAnchorEl(null)}
                  >
                    {export_check.includes("export_pdf") && (
                      <MenuItem
                        onClick={() => {

                          //wait until process complete
                          if(localStorage.getItem("loadingIco")=="true") return null;
                      
                          const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                          //check subscriptionlevel
                          let advicedata = null;
                          if (genieSelector.module?.toLowerCase()=="any code" &&(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")){
                            let { 'AUDIT REPORT': deletedProperty, ...advicedata1 } = localStorage_msg;
                            let exportadvicedata = JSON.parse(String(localStorage.getItem("finalData")));
                            advicedata = exportadvicedata
                            //advicedata = { ...advicedata1, ...advicedata };
                          }
                          else{
                           //advicedata = localStorage_msg;
                           advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                           //advicedata = { ...localStorage_msg, ...advicedata };

                          }
                          
                          if(storedAllSet)
                            exportFile({
                              doc_type: "pdf",
                              module: genieSelector?.module || "",
                              advice: advicedata,
                            });
                          else
                            toast.success('Pls. wait, we are working in background.');

                        }}
                      >
                        {export_pdf}
                      </MenuItem>
                    )}
                    {export_check.includes("export_word") && (
                      <MenuItem
                        onClick={() => {
                          //wait until process complete
                          if(localStorage.getItem("loadingIco")=="true") return null;

                          const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                           //check subscriptionlevel
                           let advicedata = null;
                           if (genieSelector.module?.toLowerCase()=="any code" &&(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")){
                              let { 'AUDIT REPORT': deletedProperty, ...advicedata1 } = localStorage_msg;
                              advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                              //advicedata = { ...advicedata1, ...advicedata };
                           }
                           else{
                            advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                            //advicedata = { ...localStorage_msg, ...advicedata };
 
                           }

                          if(storedAllSet)
                            exportFile({
                              doc_type: "docx",
                              module: genieSelector?.module || "",
                              advice: advicedata,
                            });
                          else
                            toast.success('Pls. wait, we are working in background.');
                        }}
                      >
                        {export_word}
                      </MenuItem>
                    )}
                    {export_check.includes("export_text") && (
                      <MenuItem
                        onClick={() => {
                          //wait until process complete
                          if(localStorage.getItem("loadingIco")=="true") return null;
                          
                          const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                           //check subscriptionlevel
                            let advicedata = null;
                          if (genieSelector.module?.toLowerCase()=="any code" &&(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")){
                            let { 'AUDIT REPORT': deletedProperty, ...advicedata1 } = localStorage_msg;
                            advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                            //advicedata = { ...advicedata1, ...advicedata };
                          }
                          else{
                            advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                           //advicedata = { ...localStorage_msg, ...advicedata };

                          }
                          
                          if(storedAllSet)
                            exportFile({
                              doc_type: "txt",
                              module: genieSelector?.module || "",
                              advice: advicedata,
                            });
                          else
                            toast.success('Pls. wait, we are working in background.');
                        }}
                      >
                        {export_text}
                      </MenuItem>
                    )}
                  </Popper>
                </Stack>
              </Stack>
              <Divider />
              <Box
                component="pre"
                sx={(theme) => ({
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  // height: "calc(500vh - 800px)",
                  height: "600px",
                  "&.MuiBox-root::-webkit-scrollbar": {
                    width: 4,
                  },
                  "&.MuiBox-root::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.divider,
                    borderRadius: 4,
                  },
                  "&.MuiBox-root::-webkit-scrollbar-track": {
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#2D2D2D" : "#fff",
                  },
                })}
              >
                {
                  
                  genieSelector.prompt_name === 'AUDIT REPORT' ? (auditReportData ?? '') :  
                  (wishRemCount>0 && genieSelector.module?.toLocaleLowerCase() == "any code" && genieSelector.prompt_name == "FIX BUGS" && (localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")) ?
                  (bugFixesData ?? '') :
                  (wishRemCount>0 && genieSelector.module?.toLocaleLowerCase() == "any code" && genieSelector.prompt_name == "SUMMARY" && (localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")) ?
                  (summaryData ?? '') :
                  (localStorage_msg && (localStorage_msg as any)[genieSelector.prompt_name ? genieSelector.prompt_name : ''])
                }
              </Box>
            </Stack>
          </TabPanel>
        ) : null}
      </Box>


      {isMobile ? (
        <TabPanel value={value} >
          <Stack gap="16px" height="100%" sx={{
            padding: 0,
            "& .MuiBox-root": {
              padding: 0,  // Remove padding
            },
          }}>
            <Stack flexDirection="row" sx={{
              padding: 0,
              "& .MuiBox-root": {
                padding: 0,  // Remove padding
              },
            }}>
              <Typography
                variant="h5"
                color="text.secondary"
                fontWeight="500"
                flexGrow={1}
              >
                Results
              </Typography>
              <Stack gap="16px" flexDirection="row" sx={{
                padding: 0,
                "& .MuiBox-root": {
                  padding: 0,  // Remove padding
                },
              }}>
                
                

                <CopyToClipboard
                    text={  localStorage.getItem("finalData")?JSON.stringify( JSON.parse(String(localStorage.getItem("finalData")))  ):""

                    }
                    onCopy={() => clipboardState({ copied: true })}>
                  <IconButton>
                    <SvgIcon>
                      <CopyIcon />
                    </SvgIcon>
                  </IconButton>
                </CopyToClipboard>

                <Button
                  startIcon={
                    <SvgIcon>
                      <ExportIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="inherit"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    //console.log(genieSelector.module + "--" + isPaid)
                    if(localStorage.getItem("loadingIco")=="false"){
                      if (isPaid == null && genieSelector.module == "SMART CONTRACTS") {
                        setOpenPaidModal1(true);
                      }
                      else 
                        setAnchorEl(anchorEl ? null : event.currentTarget);
                    }
                    
                  }}
                  sx={(theme) => ({
                    borderColor: theme.palette.divider,
                  })}
                >
                  Export
                </Button> 
                <Popper
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  style={{
                    backgroundColor: '#373C47', borderRadius: '4px',
                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
                    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))'
                  }}
                // onClose={() => setAnchorEl(null)}
                >
                  {export_check.includes("export_pdf") && (
                    <MenuItem
                      onClick={() => {
                        const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                        //check subscriptionlevel
                        let advicedata = null;
                        if (genieSelector.module?.toLowerCase()=="any code" &&(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")){
                          let { 'AUDIT REPORT': deletedProperty, ...advicedata1 } = localStorage_msg;
                          let exportadvicedata = JSON.parse(String(localStorage.getItem("finalData")));
                          advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                          //advicedata = { ...advicedata1, ...advicedata };
                        }
                        else{
                         //advicedata = localStorage_msg;
                         advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                         //advicedata = { ...localStorage_msg, ...advicedata };

                        }
                        
                        if(storedAllSet)
                          exportFile({
                            doc_type: "pdf",
                            module: genieSelector?.module || "",
                            advice: advicedata,
                          });
                        else
                          toast.success('Pls. wait, we are working in background.');

                      }}
                    >
                      {export_pdf}
                    </MenuItem>
                  )}
                  {export_check.includes("export_word") && (
                    <MenuItem
                      onClick={() => {
                        const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                         //check subscriptionlevel
                         let advicedata = null;
                         if (genieSelector.module?.toLowerCase()=="any code" &&(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")){
                            let { 'AUDIT REPORT': deletedProperty, ...advicedata1 } = localStorage_msg;
                            advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                            //advicedata = { ...advicedata1, ...advicedata };
                         }
                         else{
                          advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                          //advicedata = { ...localStorage_msg, ...advicedata };

                         }

                        if(storedAllSet)
                          exportFile({
                            doc_type: "docx",
                            module: genieSelector?.module || "",
                            advice: advicedata,
                          });
                        else
                          toast.success('Pls. wait, we are working in background.');
                      }}
                    >
                      {export_word}
                    </MenuItem>
                  )}
                  {export_check.includes("export_text") && (
                    <MenuItem
                      onClick={() => {
                        const storedAllSet = JSON.parse(localStorage.getItem("allSet") || "false");
                         //check subscriptionlevel
                          let advicedata = null;
                        if (genieSelector.module?.toLowerCase()=="any code" &&(localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")){
                          let { 'AUDIT REPORT': deletedProperty, ...advicedata1 } = localStorage_msg;
                          advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                          //advicedata = { ...advicedata1, ...advicedata };
                        }
                        else{
                          advicedata = JSON.parse(String(localStorage.getItem("finalData")));
                         //advicedata = { ...localStorage_msg, ...advicedata };

                        }
                        
                        if(storedAllSet)
                          exportFile({
                            doc_type: "txt",
                            module: genieSelector?.module || "",
                            advice: advicedata,
                          });
                        else
                          toast.success('Pls. wait, we are working in background.');
                      }}
                    >
                      {export_text}
                    </MenuItem>
                  )}
                </Popper>
              </Stack>
            </Stack>
            <Divider />
            <Box
              component="pre"
              sx={(theme) => ({
                whiteSpace: "pre-wrap",
                overflow: "auto",
                padding: 0,
                "& .MuiBox-root": {
                  padding: 0,  // Remove padding
                },
                width: "100%",
                height: "calc(100vh - 400px)",
                "&.MuiBox-root::-webkit-scrollbar": {
                  width: 4,
                },
                "&.MuiBox-root::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.divider,
                  borderRadius: 4,
                },
                "&.MuiBox-root::-webkit-scrollbar-track": {
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#2D2D2D" : "#fff",
                },
              })}
            >
              {
                  genieSelector.prompt_name === 'AUDIT REPORT' ? (auditReportData ?? '') :  
                  (wishRemCount>0 && genieSelector.module?.toLocaleLowerCase() == "any code" && genieSelector.prompt_name == "FIX BUGS" && (localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")) ?
                  (bugFixesData ?? '') :
                  (wishRemCount>0 && genieSelector.module?.toLocaleLowerCase() == "any code" && genieSelector.prompt_name == "SUMMARY" && (localStorage.getItem("subscription_level") == "level_1" || localStorage.getItem("subscription_level") == "level_3")) ?
                  (summaryData ?? '') :
                  (localStorage_msg && (localStorage_msg as any)[genieSelector.prompt_name ? genieSelector.prompt_name : ''])
                }
            </Box>
          </Stack>
        </TabPanel>
      ) : null}

      {/*<Button
          onClick={() => {
            setIsPopupOpen(true);
          }}
          variant="contained"
          sx={{ mr: 2, margin: '1%', float: 'right' }}
        >
          FeedBack
        </Button> */}
      <LoadingButton
        startIcon={
          <SvgIcon>
            <FeedBackIcon />
          </SvgIcon>
        }
        sx={{
          background:
            "linear-gradient(270deg, #4BA5EB 0%, #0168B5 100%)",
          marginLeft: 5,
          color: "white",
          paddingX: "20px",
          paddingY: "12px",
          borderRadius: "4px",
          width: "fit-content",
          float: 'right',
          margin: '2% 0%'
        }}
        onClick={() => {
          setIsPopupOpen(true);
        }}>
        Submit FeedBack
      </LoadingButton>
      <Modal open={isPopupOpen} ><Box sx={{
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: "translate(-50%, -50%)",
        width: isMobile ? "95%" : 600,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      }}><div style={{ padding: '3%' }}>{genieSelector.module == 'Any Code' ? "How satisfied are you with our Audit Code?" : (genieSelector.module == 'SMART CONTRACTS' ? "How satisfied are you with our Audit Contract?" : "How satisfied are you with Code Convert?")}</div>

        <div style={{ padding: '3%' }}>{ratings.map((rating, index) => (
          <Button style={{ backgroundColor: rating <= feedbackForm.rating ? "#187BC6" : '#FFC107', color: '#FFFF', margin: '5px', minWidth: '50px' }} onClick={() => handleRating(rating)}>{rating}</Button>

        ))}</div>

        <TextareaAutosize
          aria-label="Comments"
          minRows={3}
          placeholder="Enter your comments(Optional)"
          onChange={handleTextAreaChange}
          style={{ width: '100%', marginBottom: '10px' }}
        />
        <div style={{ paddingTop: '3%', width: '100%' }}>
          <Button
            onClick={() => {
              setIsPopupOpen(false);
            }}
            variant="contained"
            sx={{ mr: 2, /* backgroundColor: '#DC3545', */ float: 'right' }}
          >
            Close
          </Button>
          <Button variant="contained" sx={{ mr: 2,/*  backgroundColor: '#198754', */ float: 'right' }}
            onClick={handleFormSubmit}

          >
            Submit
          </Button></div></Box></Modal>

      <Modal
        open={openPaidModal}
        onClose={() => setOpenPaidModal(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Sorry! you can see this only from a paid plan.</p>
          <Stack flexDirection="row">
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <PayNowButton></PayNowButton>
            </Box>

            <Button
              onClick={() => {
                localStorage.setItem("localStorage_msg",JSON.stringify(localStorage_msg))
                setOpenPaidModal(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>


      <Modal
        open={openPaidModalAnyCode}
        onClose={() => setOpenPaidModalAnyCode(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "80%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Sorry! you can see this after plan upgrade. Click on Pay Now/Upgrade Now button on top.</p>
          <Stack flexDirection="row">
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <PayNowButton></PayNowButton>
            </Box>

            <Button
              onClick={() => {
                localStorage.setItem("localStorage_msg",JSON.stringify(localStorage_msg))
                setOpenPaidModalAnyCode(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>


      <Modal
        open={openPaidModal1}
        onClose={() => setOpenPaidModal1(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : 600,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <p id="child-modal-description">Sorry! you can do this only from a paid plan.</p>
          <Stack flexDirection="row">
            <Box sx={{ display: "inline-block", margin: "0 10px" }}>
              <PayNowButton></PayNowButton>
            </Box>

            <Button
              onClick={() => {
                localStorage.setItem("localStorage_msg",JSON.stringify(localStorage_msg))
                setOpenPaidModal1(false);
              }}
              variant="contained"
              sx={{ mr: 2 }}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}


