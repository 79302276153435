/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

interface Props {
  color: string;
  className: any;
}

export const Tag = ({ color = "white", className }: Props): JSX.Element => {
  return (
    <svg
      className={`tag ${className}`}
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M15.3375 3.2375L5.25 5.25L3.2375 15.3375C3.20596 15.4986 3.21455 15.6649 3.26251 15.8219C3.31047 15.9788 3.39633 16.1216 3.5125 16.2375L16.5625 29.2875C16.6545 29.3816 16.7643 29.4564 16.8856 29.5075C17.0069 29.5585 17.1372 29.5848 17.2687 29.5848C17.4003 29.5848 17.5306 29.5585 17.6519 29.5075C17.7732 29.4564 17.883 29.3816 17.975 29.2875L29.2875 17.975C29.3816 17.883 29.4564 17.7732 29.5075 17.6519C29.5585 17.5306 29.5848 17.4003 29.5848 17.2687C29.5848 17.1372 29.5585 17.0069 29.5075 16.8856C29.4564 16.7643 29.3816 16.6545 29.2875 16.5625L16.2375 3.5125C16.1216 3.39633 15.9788 3.31047 15.8219 3.26251C15.6649 3.21455 15.4986 3.20596 15.3375 3.2375V3.2375Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        className="path"
        d="M10.5 12C11.3284 12 12 11.3284 12 10.5C12 9.67157 11.3284 9 10.5 9C9.67157 9 9 9.67157 9 10.5C9 11.3284 9.67157 12 10.5 12Z"
        fill={color}
      />
    </svg>
  );
};

Tag.propTypes = {
  color: PropTypes.string,
};
