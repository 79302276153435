import { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  Divider,
  FormControl,
  TextField,
  SvgIcon,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialTwitter,
  LoginSocialGithub,
  LoginSocialLinkedin,
  IResolveParams,
} from "reactjs-social-login";
import { LoadingButton } from "@mui/lab";
//import { useCookies } from "react-cookie";
import { setCookie, getCookie, removeCookie } from "../..//utils/cookies.js";
import BackSignin from "../../assets/back_signin.png";
import Logo from "../../logo.svg";

import { ReactComponent as GoogleIcon } from "../../assets/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../assets/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/ico_twtter.svg";
import { ReactComponent as GithubIcon } from "../../assets/ico_github.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/ico_linkedin.svg";
// import body2 from "../pages/auth/text-2.png";
import img54 from '../../assets/newimg/logo.png';

import {
  useSigninUserMutation,
  useSocialAuthMutation,
} from "../../redux/api/authApi";
import { useAppDispatch } from "../../redux/store";
import { useSubscribeNewsMutation } from "../../redux/api/authApi";
import { setModule } from "../../redux/features/genieSlice";



const newsSubscribeform = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  status: string(),
})

export type NewsSubscriptionForm = TypeOf<typeof newsSubscribeform>

const signinSchema = object({
  email: string()
    .min(1, "Email address is required")
    .email("Email address is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters"),
});

export type SigninInput = TypeOf<typeof signinSchema>;

const SigninPage = () => {

  const [signinUser, signinState] = useSigninUserMutation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const [authSocial, socialState] = useSocialAuthMutation();

  const REDIRECT_URI = window.location.href;

  useEffect(() => {
    
    if (signinState.isSuccess) {

      localStorage.clear();
      toast.success("Login Success");

      localStorage.setItem("logged_in", "true");
      localStorage.setItem("free_plan", signinState.data.free_plan.toString());
      localStorage.setItem("remaining_wish_count", signinState.data.remaining_wish_count.toString());
      const subscriptionLevel = signinState.data.subscription_level || '';
      localStorage.setItem("subscription_level", subscriptionLevel);
      localStorage.setItem("user_email", signinState.data.user_email || '');
      localStorage.setItem('userRole', signinState.data.role);

      if (!signinState.data.verified) navigate("/verify");
      else {
        if (signinState.data.role === "user") {
          if (localStorage.getItem("module") === "Any Code") {
            dispatch(setModule("Any Code"));
            navigate("/codegenie/any_code");
          }
          else if (localStorage.getItem("module") === "SMART CONTRACTS") {
            dispatch(setModule("SMART CONTRACTS"));
            navigate("/codegenie/smart_contracts");
          }
          else if (signinState.data.initial_product === "Any Code") {
            localStorage.setItem("module", "Any Code");
            dispatch(setModule("Any Code"));
            navigate("/codegenie/any_code");
          }
          else {
            localStorage.setItem("module", "SMART CONTRACTS");
            dispatch(setModule("SMART CONTRACTS"));
            navigate("/codegenie/smart_contracts");
          }
        } else {
          navigate("/admin/dashboard");
        }
      }
    }
    if (signinState.isError) {
      localStorage.setItem("logged_in", "false");
      
      if (Array.isArray((signinState.error as any).data.detail)) {
        (signinState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((signinState.error as any).data.detail);
    }
  }, [signinState]);

  useEffect(() => {
    if (socialState.isSuccess) {
      toast.success("Social signup success");
      
      localStorage.setItem("logged_in", "true");
      localStorage.setItem('userRole', socialState.data.role);

      if (socialState.data.role === "user") {
        localStorage.setItem("module", "SMART CONTRACTS");
        dispatch(setModule("SMART CONTRACTS"));
        navigate("/codegenie/smart_contracts");
      } else {
        navigate("/admin/dashboard");
      }
    }
    if (socialState.isError) {
      
      localStorage.setItem("logged_in", "false");
      

      if (Array.isArray((socialState.error as any).data.detail)) {
        (socialState.error as any).data.detail.map((el: any) =>
          toast.error(`${el.loc[1]} ${el.msg}`)
        );
      } else toast.error((socialState.error as any).data.detail);
    }
  }, [socialState]);

  const methods = useForm<SigninInput>({
    resolver: zodResolver(signinSchema),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmitHandler: SubmitHandler<SigninInput> = (values) => {
    signinUser(values);
  };

  const [subscribeVal, setSubscribeVal] = useState<NewsSubscriptionForm>({ email: '', status: '' })
  const [newsSubsctiptionMutation, newsSubscriptionState] = useSubscribeNewsMutation();
  
  const methodsfoot = useForm<NewsSubscriptionForm>({
    resolver: zodResolver(newsSubscribeform),
  });
  const {
    register : RegisterFoot,
    handleSubmit: handleSubmitFoot,
    formState: { errors:Errors },
  } = methodsfoot;
  const onSubmitHandlerFoot: SubmitHandler<NewsSubscriptionForm> = (values) => {
    newsSubsctiptionMutation(values);
  };
  const handleSubscribeNews = () => {
    newsSubsctiptionMutation(subscribeVal)
      .unwrap()
      .then((response) => {
        console.log('news subscription=>', response);
          const status = response.status;
        
          console.log(status);
          if (status === 'success') {
            toast.success(response.message);
            setSubscribeVal({
              email: '',
              status: ''
            });
          }
      })
      .catch((error) => {
        toast.error(error?.data?.detail ?? 'Something went wrong!');
        console.error('Error sending query:', error);
      });
  }
  return (
    <>
     <div className="container"></div>
     <div className="row">
      <div className="col-md-6 text-white text-center mt-2">
        <div className="px-sm-0 px-2">
          <div>
            <div className="Ac-1">        
            <div>
              <Grid item xs={6} position="relative">
              <img className="signinimg" src={BackSignin} alt="Signup Background" />
              <Typography
                position="absolute"
                bottom={103}
                left={0}
                variant="h4"
                width="100%"
                fontWeight={600}
                color="white"

              >
                Audit, Fix, Refactor, Document any Code Within Seconds <br></br>
              </Typography>
            
            </Grid>
            </div>
          </div> <br></br> <br></br>     
          </div>     
        </div>    
      </div>
      <div className="col-md-6 my-auto">      
        <div className="px-sm-0 px-2">
        <Box
                position="absolute"
                top={50}
                display="flex"
                alignItems="center"
                gap={4}
              >
              <div className="">
                <Typography variant="h5" fontWeight={600} color="text.secondary" onClick={() => navigate('/')}>
                <img width={44} height={44} src={Logo} alt="Logo" onClick={() => navigate('/')} />
                  CodeGenie
                </Typography>
                </div>
              </Box>
        <p className="Sign-1">
              <Box width={464}>
                <div className="Sign-2">
                <Typography variant="h4" mb={4} color="text.secondary">
                  Sign in your account
                </Typography>
                </div>
                <FormProvider {...methods}>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit(onSubmitHandler)}
                  >
                    <Stack gap={3}>
                      <FormControl fullWidth>
                        <Typography mb={1} color="text.secondary">
                          Email address
                        </Typography>
                        <TextField
                          {...register("email")}
                          type="email"
                          placeholder="Enter your email"
                          error={!!errors["email"]}
                          helperText={errors["email"]?.message} 
                          
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <Stack
                          mb={1}
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Typography color="text.secondary">Password</Typography>
                          <Link
                            to="/forgot"
                            style={{
                              color: "#0168B5",
                              textDecoration: "none",
                            }}
                          >
                            Forgot Password?
                          </Link>
                        </Stack>
                        <TextField
                          {...register("password")}
                          type="password"
                          placeholder="Enter your password"
                          error={!!errors["password"]}
                          helperText={errors["password"]?.message}
                        />
                      </FormControl>
                    </Stack>
                    <LoadingButton
                      loading={signinState.isLoading}
                      type="submit"
                      fullWidth
                      sx={{
                        height: 56,
                        background:
                          "linear-gradient(90deg, #036AB7 0%, #4BA5EB 100%)",
                        color: "white",
                        mt: 4,
                      }}
                    >
                      Log in
                    </LoadingButton>
                  </Box>
                </FormProvider>
                <Stack
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  height={44}
                  mt={3}
                >
                  <Divider sx={{ flexGrow: 1 }} />
                  <Typography>Or</Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Stack>
                <Stack direction="column" alignItems="center">
                  <Box
                    width={56}
                    height={56}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid #CACBCC"
                    borderRadius="50%"
                  >
                    <LoginSocialGoogle
                      client_id={process.env.REACT_APP_GG_APP_ID || ""}
                      scope="email"
                      onResolve={({ provider, data }: IResolveParams) => {
                        console.log(data);
                        if (data)
                          authSocial({
                            provider: provider as string,
                            email: data?.email||"" as string,
                            name: data?.name||"" as string,
                            id: data.sub as string,
                          });
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <SvgIcon>
                        <GoogleIcon />
                      </SvgIcon>
                    </LoginSocialGoogle>
                  </Box>
                </Stack>
                <Typography mt={3}>
                  New user?{" "}
                  <Link
                    to="/signup"
                    style={{
                      color: "#0168B5",
                    }}
                  >
                    Create an account
                  </Link>
                </Typography>
              </Box>
            </p> 
        </div>
      </div>
    </div> 

    <footer>
    
    <div className="py-5 mx-sm-0 mx-2 backgroundbg">
        <div className="container">
          <div className="row px-sm-0 px-2">
            <div className="col-lg-3 text-white text-lg-start text-center">
              <div>
                <div>
                  <img src={img54} alt="" />

                </div>
                <p className="fw_samibold">
                  OceSha/Code Genie Los Angeles, Ca 310-748-8901
                </p>
              </div>
            </div>
            <div className="col-lg-3 text-white d-lg-none d-block  text-lg-start text-center">
              <div>
                <h6 className="f_heading mb-4">
                  Get news in your mailbox right away!
                </h6>
                <p className="fw_samibold">
                  In addition, stay in loop for our exclusive launches and limited
                  time deals.
                </p>
              </div>
              <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Search" />
                <button className="btn btn-primary" type="submit">
                  Subscribe
                </button>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4">
              <div>
              <div className="text-white">
                <h6 className="fw_samibold">Products</h6>
                <ul
                  className="ps-0 mt-3 footer_dec"
                  style={{ listStyle: "none" }}
                >
                  <li>
                    <a target="_blank" href="https://ai-course.code-genie.ai/courses/ArtificialIntelligence">AI Course</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://consulting.code-genie.ai/">AI Consulting</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-Rise-Artificial-Intelligence-ebook/dp/B0C7BPRX9J/ref=sr_1_1?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-1">AI Book</a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.amazon.com/TECH-WARZ-BLOCKCHAIN-CRYPTOCURRENCY-Money-ebook/dp/B0C83TGQSY/ref=sr_1_2?qid=1700062906&refinements=p_27%3ARohan+Hall&s=books&sr=1-2">Blockchain Book</a>
                  </li>
                </ul>
              </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4 text-lg-start text-sm-center">
              <div>
                <div className="text-white">
                  <h6 className="fw_samibold">Information</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a target="_blank" href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://codegenie.getrewardful.com/signup?_gl=1*wfwhnc*_gcl_au*MTMwOTQ5ODcxNy4xNjkyODU4NDk3*_ga*MTA4OTc3NTMyNC4xNjkyODUzODcz*_ga_YJYFH7ZS27*MTY5Mjg3OTg0NS40LjEuMTY5Mjg4NjM1MC41Mi4wLjA.&_ga=2.74546258.159718116.1692853906-1089775324.1692853873">Affiliate</a>
                    </li>
                    <li>
                      <a target="_blank" href="/contactus">Contact us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-sm-4  text-lg-start text-sm-end">
              <div>
                <div className="text-white">
                  <h6 className="fw_samibold">Support</h6>
                  <ul
                    className="ps-0 mt-3 footer_dec"
                    style={{ listStyle: "none" }}
                  >
                    <li>
                      <a target="_blank" href="/terms">Terms and Conditions</a>
                    </li>
                    <li>
                      <a target="_blank" href="/privacy">Privacy &amp; Cookie policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 text-white d-lg-block d-none">
              <div>
                <h5 className="mb-4 f_heading">
                  Get news in your mailbox right away!
                </h5>
                <p className="fw_samibold">
                  In addition, stay in loop for our exclusive launches and limited
                  time deals.
                </p>
              </div>
              {/* News Subscribe */}
              <FormProvider {...methodsfoot}>
         <div className="input-group mb-3" onSubmit={handleSubmitFoot(onSubmitHandlerFoot)}>
                    <input className={`form-control ${Errors["email"] ? "error" : ""}`}
                      {...RegisterFoot("email")}
                      type="email"
                      placeholder="Enter your mail"
                      // error={!!errors["email"]}
                      // helperText={errors["email"]?.message}
                      onChange={(e) => setSubscribeVal({ ...subscribeVal, email: e.target.value })} />
                    <button className="btn btn-primary" type="submit" onClick={handleSubscribeNews}>
                      Subscribe
                    </button>
                    {Errors["email"] && (
                      <span className="error-message">{Errors["email"]?.message}</span>
                    )}
                  </div>
                  </FormProvider>
                  {/*  */}
            </div>
          </div>
        </div>
      </div>
                          
      
    </footer>
    </>
  );
};

export default SigninPage;
